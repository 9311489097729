import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {MouseEventHandler, useEffect, useRef, useState} from "react";
import SocialIconLink from "./components/SocialIconLink";
import TopNav from "./TopNav";
import ProjectsSection from "./sections/ProjectsSection";
import {Helmet} from "react-helmet";
import bgTopImg1 from "./assets/img/bgTop_1.webp";
import bgTopImg2 from "./assets/img/bgTop_2.webp";
import aboutUsLogoImg from "./assets/img/aboutUsLogo.png";
import bgAboutUsImg from "./assets/img/bgAboutUs.webp";
import logoImg from "./assets/img/logo512.png";

type Vec = {
  x: number;
  y: number;
};

function App() {
  const [pos, setPos] = useState<Vec>({x: 0, y: 0});
  const [rng, setRng] = useState<number | undefined>(undefined);
  const bgRef = useRef<HTMLDivElement>(null);

  // onMouseMove event handler
  const bgParallax: MouseEventHandler<HTMLDivElement> = (e) => {
    setPos({
      x: (e.pageX - window.innerWidth / 2) / 30,
      y: (e.pageY - window.innerHeight / 2) / 30,
    });
  };

  useEffect(() => {
    if (rng === undefined) {
      setRng(Math.floor(Math.random() * 2) + 1);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Scarce Games</title>
        <meta
          name="description"
          content="Scarce Games is an indie game studio based in Prague, Czech Republic. 'We make games of quality, which are few and far between.'"/>
        <meta name="keywords"
              content="Indie Game Studio, Prague, Czech Republic, Dark Omen, Scientist's DOOM, Scarce Games, Unity, Low Poly, ARPG"/>
        <meta name="google-site-verification" content="_lqg91LDH7zsdVPBwpzcb0l2RmSjBAQYHknxbiubjWA"/>
      </Helmet>
      <VStack
        alignItems={"center"}
        color={"white"}
        spacing={"0"}
        w={"100%"}
        overflow={"hidden"}
        onMouseMove={bgParallax}
      >
        <Box
          h={{base: "90vh", lg: "95vh"}}
          w={"120%"}
          m={"-8vh 0"}
          p={"0 10%"}
          display={"flex"}
          flexDir={"column"}
          alignItems={"center"}
          color={"white"}
          bgColor={"gray.100"}
          bgImg={rng === 1 ? bgTopImg1 : bgTopImg2}
          bgSize={"cover"}
          bgRepeat={"no-repeat"}
          bgPos={"center"}
          backgroundPosition={{
            base: "0",
            lg: `${-pos.x}px ${-pos.y}px`,
          }}
          ref={bgRef}
        >
          <Box
            w={"100%"}
            h={"100%"}
            m={"-4vh 0"}
            bg={
              "linear-gradient(0deg, rgba(16,16,16,1) 0%, rgba(16,16,16,0) 10%)"
            }
          >
            <Box
              w={"100%"}
              h={"100%"}
              m={"8vh 0"}

            >
              <TopNav/>
              <Flex alignItems={"center"} minH={"60vh"}>
                <Heading
                  as={"h1"}
                  size={{base: "xl", lg: "2xl"}}
                  maxW={"1000px"}
                  p={{"base": "3rem", "md": "5rem"}}
                  m={"0 auto"}
                  wordBreak={"break-word"}
                >
                  <p>We make games of quality,</p>
                  which are few and far between
                </Heading>
              </Flex>
            </Box>
          </Box>
        </Box>
        <ProjectsSection/>
        <Box
          w={"100%"}
          bgImg={bgAboutUsImg}
          bgSize={"cover"}
          bgRepeat={"no-repeat"}
        >
          <Box p={{base: "3rem 0", lg: "0"}} w={"100%"} h={"100%"} bg={
            "linear-gradient(0deg, rgba(16,16,16,1) 10%, rgba(16,16,16,0) 50%, rgba(16,16,16,1) 90%)"
          }>
            <Stack
              w={{base: "80%", lg: "60%"}}
              pb={"5vw"}
              maxW={{base: "100%", lg: "1000px"}}
              m={"0 auto"}
              spacing={{base: "0", md: "-30%", lg: "-10%"}}
              direction={{base: "row"}}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Image
                src={aboutUsLogoImg}
                opacity={"0.1"}
                w={{base: "50%", lg: "40%"}}
                pt={"5vw"}
                display={{base: "none", md: "flex"}}
                draggable={false}
              />
              <Box
                w={{base: "100%", md: "80%", lg: "60%"}}
                textAlign={"left"}
                zIndex={"1"}
              >
                <Heading
                  as={"h3"}
                  size={"lg"}
                  pt={"1rem"}
                  pb={"1rem"}
                  id="about"
                  textAlign={{base: "center", md: "left"}}
                  color={"white"}
                >
                  About us
                </Heading>
                <Stack spacing={"1rem"} color={"gray.900"}>
                  <Text>
                    We’re up-and-coming indie game studio based in Prague, Czech
                    Republic. We kicked off our journey with Scientist’s DOOM, a
                    story-driven topdown RPG game which started as a school
                    project and later won us 1st place at Gamer Pie’s Speed Dating
                    competition and made total buzz at Game Access, 2018.
                  </Text>
                  <Text>
                    We didn’t stop there and since 2020, we’ve been hard at work
                    developing our next game - Dark Omen.
                  </Text>
                  <Text>
                    Our goal is to make games that are played for fun. We’re
                    developers at day, and gamers at night. We strive to make
                    engaging, immersive and memorable experiences that are
                    accessible by everyone.
                  </Text>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box w={"100%"} bg={"#000"}>
          <VStack w={"100%"} bg={
            "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(16,16,16,1) 100%)"
          } spacing={"5rem"}>
            <VStack p={{base: "3rem 0", "lg": "3rem 0"}} spacing={{"base": "2rem", "lg": "3rem"}}>
              <HStack spacing={"0.5rem"} alignItems={"flex-start"} m={"0 auto"}>
                <Heading
                  as={"h3"}
                  size={"lg"}
                  id="discord"
                >
                  Join Our
                </Heading>
                <Heading
                  as={"h3"}
                  size={"lg"}
                  id="projects"
                  bgGradient='linear(to-l, #5865F2, #959DFA)'
                  bgClip='text'
                >
                  Discord
                </Heading>
              </HStack>
              <Text w={{"base": "80%", "lg": "50%"}} textAlign={"center"} display={"block"}
                    color={"gray.900"}>
                We’re on Discord. Join us and get the latest updates,
                ask questions and chat with us and the community from all over the world!
              </Text>
              <Button
                variant={"solid"}
                size="lg"
                color="white"
                bg='#7681F6'
                transition={"all 0.3s ease-in-out"}
                borderRadius={"5rem"}
                p="1.5rem 2rem"
                _hover={{"color": "black", "bg": "white", "p": "1.5rem 3rem"}}
                formTarget="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "https://discord.gg/gBQmdKB2uH"
                }}>
                Join Now
              </Button>
            </VStack>
            <Stack
              pr={"5rem"}
              pb={"5rem"}
              pl={{base: "5rem", md: "11rem"}}
              w={{base: "100%", lg: "1000px"}}
              direction={{base: "column", md: "row"}}
              spacing={{base: "3rem", md: 0}}
              alignItems={{base: "center", md: "start"}}
              textAlign={{base: "center", md: "left"}}
            >
              <Link href="#" w={{base: "100%", md: "33%"}}>
                <Image
                  src={logoImg}
                  w={"7rem"}
                  m={{base: "0 auto", md: "0"}}
                  draggable={false}
                />
              </Link>
              <Box w={{base: "100%", md: "33%"}}>
                <Heading as="h4" size="md" color="gray.900">
                  Games
                </Heading>
                <VStack
                  color={"white"}
                  fontWeight="bold"
                  alignItems={"left"}
                  mt="2rem"
                  spacing={"1rem"}
                >
                  <Link href="#projects">Dark Omen</Link>
                  <Link href="#projects">Scientist&apos;s DOOM</Link>
                </VStack>
              </Box>
              <Box w={{base: "100%", md: "33%"}}>
                <Heading as="h4" size="md" color="gray.900" id="contact">
                  Socials
                </Heading>
                <HStack mt="2rem" justifyContent={{base: "center", md: "left"}}>
                  <SocialIconLink href="https://discord.gg/gBQmdKB2uH" network="discord" size={40}/>
                  <SocialIconLink
                    href="https://www.youtube.com/channel/UCdEMTUD8Myx_Wgw5qV4euXQ"
                    network="youtube"
                    size={40}
                  />
                  <SocialIconLink
                    href="https://twitter.com/scarcegamesdevs"
                    network="twitter"
                    size={40}
                  />
                </HStack>
              </Box>
            </Stack>
          </VStack>
        </Box>
      </VStack>
    </>
  );
}

export default App;
