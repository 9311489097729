import { Box, Flex, Heading, Link, Show, Stack, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import ProjectCard from "../components/ProjectCard";
import doFeatureImg from "../assets/img/sg_website_feature_do_1x.webp";
import sdFeatureImg from "../assets/img/sg_website_feature_sd_1x.webp";
import bgProjectsImg from "../assets/img/bgProjects.webp";

interface ProjectsSectionProps {

}

const ProjectsSection: FunctionComponent<ProjectsSectionProps> = () => {
  return <Box
    w={"100%"}
    bgImg={bgProjectsImg}
    bgSize={"cover"}
    bgRepeat={"no-repeat"}
  >
    <Flex
      w={"100%"}
      p={{ base: "5rem 0", "lg": "5vw 0" }}
      pb={{ base: "3rem", lg: "5vw" }}
      bg={
        "linear-gradient(0deg, rgba(16,16,16,1) 0%, rgba(16,16,16,0.5) 20%)"
      }
      justifyContent={"center"}
    >
      <VStack w={"100%"}>
        <Heading
          as={"h3"}
          size={"lg"}
          pb={{ base: "3rem", lg: "5vw" }}
          m={"0 auto"}
          id="projects"
        >
          What we&apos;re working on
        </Heading>
        <Stack
          w={"min(80%, 1000px)"}
          justifyContent={"center"}
          alignItems={{ base: "center", lg: "flex-end" }}
          spacing={"3rem"}
          direction={{ base: "column-reverse", lg: "row" }}
        >
          <ProjectCard
            imgSrc={sdFeatureImg}
            projectName={"Scientist's Doom"}
            projectDescription={
              <>
                The project where it all started ... Scientist&apos;s DOOM is our very first game developed during our studies at CTU, Prague
                and later featured at Game Access 2018.
              </>
            } />
          <ProjectCard
            link="#projects"
            imgSrc={doFeatureImg}
            projectName={"Dark Omen"}
            projectDescription={
              <>
                Topdown action RPG meets roguelite in Low-Poly. Powered by the Unity engine,
                this is our latest project currently in development. Make sure you join our <Link href="#discord" bgGradient='linear(to-l, #5865F2, #959DFA)'
                  bgClip='text'>Discord</Link> for latest updates!
              </>
            } />
          <Show above="lg">
            <Box w={{ base: "80%", md: "50%", lg: "33%" }} />
          </Show>
        </Stack>
      </VStack>
    </Flex>
  </Box>;
}

export default ProjectsSection;