import {CloseIcon, HamburgerIcon} from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Image,
  Link as ChakraLink,
  Stack,
  VStack,
} from "@chakra-ui/react";
import {ReactNode, useState} from "react";
import {HashLink} from "react-router-hash-link";
import logoTopnavImg from "./assets/img/logo_topnav512.png";

type NavLinkProps = {
  children: ReactNode;
  href?: string;
  fontSize?: string;
  onClick?: () => void;
};

const NavLink = ({
  children,
  href = "#",
  fontSize = "md",
  onClick = () => {
  },
}: NavLinkProps) => (
  <ChakraLink
    as={HashLink}
    fontWeight={"bold"}
    fontSize={fontSize}
    _hover={{
      textDecoration: "none",
    }}
    to={href}
    onClick={onClick}
  >
    {children}
  </ChakraLink>
);

export default function TopNav() {
  const [display, changeDisplay] = useState<string>("none");

  return (
    <>
      <Flex
        zIndex={2}
        boxSizing={"border-box"}
        pt={"6vh"}
        pb={"2vh"}
        pl={"5vw"}
        pr={"5vw"}
        w={"100%"}
      >
        <Box
          py={4}
          display={"flex"}
          flexWrap={"nowrap"}
          flex={"1 0 67%"}
          height={"inherit"}
          alignItems={"center"}
          m={"0 auto"}
          maxW={"1000px"}
        >
          <Image
            src={logoTopnavImg}
            alt="SG"
            maxH={"60px"}
            maxW={"60%"}
            cursor="pointer"
            flexGrow={"0"}
            flexShrink={"0"}
            draggable={false}
          />
          <Stack
            direction={"row"}
            spacing={10}
            ml={"auto"}
            flexGrow={"1"}
            flexShrink={"1"}
            justify={"flex-end"}
            display={{base: "none", md: "flex"}}
          >
            <NavLink href="#projects">projects</NavLink>
            <NavLink href="#about">about us</NavLink>
            <NavLink href="#contact">contact</NavLink>
          </Stack>
          <HStack
            w="100%"
            justify={"flex-end"}
            display={{base: "flex", md: "none"}}
          >
            <HamburgerIcon
              aria-label="Open Menu"
              boxSize="2rem"
              color={"white"}
              bgColor={"transparent"}
              _hover={{
                bgColor: "transparent",
              }}
              onClick={() => changeDisplay("flex")}
            />
          </HStack>

          <Flex
            w="100vw"
            display={display}
            bgColor="gray.100"
            zIndex={20}
            h="100vh"
            pos="fixed"
            top="0"
            left="0"
            overflowY="auto"
            flexDir="column"
          >
            <Flex justify="flex-end" p={"5vw"}>
              <CloseIcon
                mt={2}
                mr={2}
                aria-label="Open Menu"
                boxSize="2rem"
                color={"white"}
                onClick={() => changeDisplay("none")}
              />
            </Flex>

            <VStack
              align="center"
              spacing="3rem"
              height={"60vh"}
              justify="center"
            >
              <NavLink
                href="#projects"
                fontSize={"3xl"}
                onClick={() => changeDisplay("none")}
              >
                projects
              </NavLink>
              <NavLink
                href="#about"
                fontSize={"3xl"}
                onClick={() => changeDisplay("none")}
              >
                about
              </NavLink>
              <NavLink
                href="#contact"
                fontSize={"3xl"}
                onClick={() => changeDisplay("none")}
              >
                contact
              </NavLink>
            </VStack>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}
