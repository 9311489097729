import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Maintenance from "./Maintenance";
import reportWebVitals from "./reportWebVitals";

import "@fontsource/cascadia-code";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";

const theme = extendTheme({
  fonts: {
    body: `'Poppins', sans-serif`,
    heading: `'Poppins', sans-serif`,
    mono: `'Cascadia Code', monospace`,
  },
  colors: {
    gray: {
      100: "#111",
      300: "#333",
      500: "#666",
      600: "#999",
      900: "#CCC",
    },
  },
  components: {
    Code: {
      baseStyle: {
        fontFamily: "Cascadia Code",
      },
    },
    Link: {
      baseStyle: {
        "&:hover": { textDecoration: "none" },
      },
    },
    Button: {
      bg: "white",
      baseStyle: {
        color: "black",
        _hover: {
          bg: "red",
        },
      },
    },
  },
  styles: {
    global: {
      html: {
        height: "100%",
      },
      body: {
        height: "100%",
        margin: 0,
        backgroundColor: "black",
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/maintenance",
    element: <Maintenance />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
