import { Heading, Box, Image, Link, Text } from "@chakra-ui/react";
import { FunctionComponent, ReactElement } from "react";

interface ProjectImageProps {
  link?: string;
  imgSrc: string;
}

interface ProjectCardProps {
  link?: string;
  imgSrc: string;
  projectName: string;
  projectDescription: ReactElement;
}

const ProjectImage: FunctionComponent<ProjectImageProps> = (props) => {

  const img = <Image
    src={props.imgSrc}
    objectFit={"cover"}
    w={"100%"}
    transition={"translate 0.3s ease-in-out, opacity 0.3s ease-in-out"}
    opacity={"0.8"}
    borderRadius={"0.3rem"}
    _hover={{
      translate: "0 -1rem",
      opacity: "1",
    }}
    draggable={false}
  />

  if (props.link) {
    return <Link href={props.link}>
      {img}
    </Link>
  }

  return img;
}

const ProjectCard: FunctionComponent<ProjectCardProps> = (props) => {
  return (
    <Box w={{ base: "80vw", lg: "33%" }}
      textAlign={"left"}>
      <ProjectImage {...props} />
      <Heading as="h3" size="md" w={"100%"} mt={"2rem"}>
        {props.projectName}
      </Heading>
      <Text w={"100%"} mt="1rem" color={"gray.900"} height={"20vh"} overflow={"hidden"}>
        {props.projectDescription}
      </Text>
    </Box>
  );
}

export default ProjectCard;